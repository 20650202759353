<template>
  <v-container fluid>
    <v-dialog v-model="this.$store.getters['Auth/is_avis_open']" max-width="500px" persistent>
      <v-card>
        <v-card-title>
          <h4 class="headline">{{ formTitle }}</h4>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col class="colll" cols="12" sm="12" md="12">
                <v-select
                  :items="this.$store.getters['Auth/get_all_legal_form'].notice_types"
                  v-model="notice.type"
                  label="Sélectionner le type d’avis"
                  item-text="description"
                  item-value="status"
                ></v-select>
                <v-text-field v-model="notice.object" label="Saisir l’objet de l’avis"></v-text-field>
                <div class="d-flex justify-space-between">
                  <date-picker
                    v-model="notice.publi_date"
                    value-type="format"
                    format="YYYY-MM-DD H:m:s"
                    type="datetime"
                    :default-value="new Date()"
                    placeholder="Renseigner la date/l’heure de publication de l’avis"
                  ></date-picker>
                </div>
              </v-col>
              <v-col
                class="colll"
                v-if="notice.type != 1 && notice.type != 8 && notice.type != 9"
                cols="12"
                sm="12"
                md="12"
              >
                <div class="d-flex justify-space-between">
                  <!-- <span v-if="notice.type != 6 && notice.type != 7">Date Limite de dêpot</span>
                  <span v-if="notice.type == 6 || notice.type == 7">Date de fin d'Expression I.</span> -->
                  <date-picker
                    v-model="notice.deadline"
                    value-type="format"
                    v-if="notice.type != 6 && notice.type != 7"
                    format="YYYY-MM-DD H:m:s"
                    :default-value="new Date()"
                    type="datetime"
                    placeholder="Renseigner la date/l’heure limite de remise des plis"
                  ></date-picker>
                  <date-picker
                    v-model="notice.deadline"
                    value-type="format"
                    v-if="notice.type == 6 || notice.type == 7"
                    format="YYYY-MM-DD H:m:s"
                    :default-value="new Date()"
                    type="datetime"
                    placeholder="Date de fin d'Expression Interet"
                  ></date-picker>
                </div>
              </v-col>
              <v-col
                class="colll"
                v-if="notice.type == 3 || notice.type == 4 || notice.type == 5"
                cols="12"
                sm="12"
                md="12"
              >
                <div class="d-flex justify-space-between">
                  <date-picker
                    v-model="notice.open_date"
                    value-type="format"
                    format="YYYY-MM-DD H:m:s"
                    :default-value="new Date()"
                    type="datetime"
                    placeholder="Renseigner la date/l’heure d’ouverture des plis (Expression d’Intérêts / Offres / Propositions)"
                  ></date-picker>
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-select
                  v-if="notice.type != 8 && notice.type != 9"
                  :items="this.$store.getters['Auth/getListPpm']"
                  v-model="notice.ppm"
                  label="Sélectionner le plan de passation concerné par l’avis"
                  item-text="title"
                  item-value="id"
                ></v-select>
                <v-select
                  :items="this.$store.getters['Auth/getListDoc']"
                  v-model="notice.doc"
                  v-if="notice.type == 8 || notice.type == 9"
                  label="Le dossier de consultation conserné"
                  item-text="object"
                  item-value="id"
                ></v-select>
                <v-file-input
                  v-model="notice.file"
                  placeholder="Charger le fichier de l'Avis d&#39;Appel à Concurrence"
                  label="Insérer le fichier de l’avis"
                  multiple
                  prepend-icon="mdi-paperclip"
                >
                  <template v-slot:selection="{ text }">
                    <v-chip small label color="primary">{{ text }}</v-chip>
                  </template>
                </v-file-input>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="close_avis"> ANNULER LA PUBLICATION</v-btn>
          <v-btn
            color="green darken-1"
            :loading="this.$store.getters['Auth/loadNotice']"
            text
            @click="addthisNotice"
            >PUBLIER L’AVIS</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-data-table
      locale="fr"
      :headers="headers"
      @click:row="goto"
      :search="search"
      no-data-text="Aucun Avis d&#39;Appel à Concurrence disponible"
      :items="this.$store.getters['Auth/getListAvis']"
      sort-by="publiDate"
      class="elevation-1 mb-5 px-5"
    >
      <template v-slot:[`item.colorState`]="{ item }">
        <!-- v-if="item.noticeType.id != 1 && item.noticeType.id != 8 && item.noticeType.id != 9" -->
        <v-icon
          medium
          :color="item.colorState.colorCode"
          >mdi-circle</v-icon
        >
      </template>
      <template v-slot:[`item.deadline`]="{ item }">{{
        item.deadline ? item.deadline : "--" | moment("dddd, Do MMMM YYYY à HH:mm:ss A Z")
      }}</template>
      <template v-slot:[`item.publiDate`]="{ item }">{{
        item.publiDate | moment("dddd, Do MMMM YYYY à HH:mm:ss A Z")
      }}</template>
      <template v-slot:top>
        <v-toolbar flat color="white" class="mb-5">
          <v-toolbar-title v-show="$vuetify.breakpoint.mdAndUp">Liste détaillée des avis d’appel à la Concurrence</v-toolbar-title>
          <v-divider class="mx-4" inset vertical v-show="$vuetify.breakpoint.mdAndUp"></v-divider>
          <v-spacer v-show="$vuetify.breakpoint.mdAndUp"></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Recherchez un avis"
            single-line
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-btn small color="primary" dark class="mb-2" @click="open_avis">
            <v-icon small class="mr-2">mdi-plus</v-icon>AJOUTER UN NOUVEAU TYPE D’AVIS
          </v-btn>
        </v-toolbar>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  components: { DatePicker },
  data: () => ({
    search: "",
    items: [
      {
        text: "Tableau de bord",
        disabled: false,
        href: "/dashboard"
      },
      {
        text: "Mes Avis",
        disabled: true
      }
    ],
    dog: true,
    noticePpm: [
      {
        id: 1,
        title: "Réparation route nationale"
      }
    ],
    noticeType: [],
    notice: {
      object: "",
      publi_date: null,
      open_date: null,
      deadline: null,
      doc: 0,
      type: [],
      ppm: [],
      file: []
    },
    dialog: false,
    headers: [
      {
        text: " Statuts des avis en cours ou expirés",
        align: "start",
        sortable: false,
        value: "colorState"
      },
      {
        text: "Objet de l’avis",
        sortable: true,
        value: "object"
      },
      {
        text: "Date/heure de publication de l’avis",
        sortable: true,
        value: "publiDate"
      },
      { text: "Date/heure limite de remise des plis (Expression d’Intérêts / Offres / Propositions)", value: "deadline", sortable: true },
      { text: "Types d'avis", value: "noticeType.title", sortable: true },
      { text: "PPM", value: "ppm.title", sortable: true }
    ],
    desserts: [],
    editedIndex: -1
  }),
  filters: {
    date(val) {
      return val ? val.toLocaleString() : "";
    }
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? " Création d’un nouvel avis d’appel à la concurrence" : "Création du Plan de Passation des Marchés";
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    }
  },
  created() {
    const cur_year = new Date().getFullYear();
    this.$store.dispatch("Auth/loadAllPPM", cur_year);
    this.$store.dispatch("Auth/loadAllNotice");
    this.$store.dispatch("Auth/loadAllDoc");
    this.$store.dispatch("Auth/getData");
  },
  methods: {
    ...mapActions({
      addNotice: "Auth/addNotice",
      loadAllNotice: "Auth/loadAllNotice"
    }),
    open_avis() {
      this.$store.commit("Auth/OPEN_AVIS", true);
    },
    close_avis() {
      this.$store.commit("Auth/OPEN_AVIS", false);
    },
    goto(value) {
      const { id } = value;
      this.$router.push({ name: "showNotice", params: { id: id } });
    },
    addthisNotice() {
      const data = new FormData();
      data.append("object", this.notice.object);
      data.append("notice_type", this.notice.type);
      data.append("ppm", this.notice.ppm);
      if (this.notice.publi_date) {
        data.append("publi_date", this.notice.publi_date);
      }
      if (this.notice.deadline) {
        data.append("deadline", this.notice.deadline);
      }
      if (this.notice.open_date) {
        data.append("date_publi_ouverture", this.notice.open_date);
      }
      if (this.notice.doc) {
        data.append("doc_id", this.notice.doc);
      }
      if (this.notice.file) {
        data.append("file", this.notice.file[0]);
      } else {
        data.append("file", null);
      }
      this.addNotice(data);

      this.notice.object = "";
      this.notice.publi_date = null;
      this.notice.open_date = null;
      this.notice.deadline = null;
      this.notice.doc = 0;
      this.notice.type = [];
      this.notice.ppm = [];
      this.notice.file = [];
    },
    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = { ...item };
      this.dialog = true;
    },

    deleteItem(item) {
      const index = this.desserts.indexOf(item);
      confirm("Are you sure you want to delete this item?") && this.desserts.splice(index, 1);
    },
    getPPM(item) {
      console.log(item);
      this.$router.push({ name: "user", params: { ppm: item } });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.desserts[this.editedIndex], this.editedItem);
      } else {
        this.desserts.push(this.editedItem);
      }
      this.close();
    }
  }
};
</script>
<style>
.vue-daterange-picker {
  width: 100% !important;
}
.mx-datepicker {
  width: 100% !important;
}
.w-62em {
  height: 62em !important;
  width: 100% !important;
}
.v-card__text {
  width: 100% !important;
}
.vue-friendly-iframe iframe {
  width: 100% !important;
  height: 62em !important;
}
</style>
